import darraghSeal from '../images/darraghSeal.gif';
import surprisedMe from '../images/surprisedMe.gif';
import styled from 'styled-components';

const HeadSpace = styled.header`
    min-height: min(200px, 20vh);
    max-height: min(400px,40vh );
    display:flex;
    flex-flow: row nowrap;
    text-align: center;

    background-color:rgba(0,0,0,.5);

    @keyframes head-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

    & .logo {
        min-height: min(100px, 15vh);
    max-height: min(200px,20vh );
    animation: 'head-logo-spin' 4s infinite
    /* background-image: url(${darraghSeal}); */
    }

    
    
`;

const Header = (props) => {
    return (<>
        <HeadSpace className="App-header">
        <img className='logo' src={darraghSeal} alt="Darragh Seal"/>
        
        <div><h1>Darragh's</h1>
        <h3>On The Point</h3>
        </div>
        <img className='me' src={surprisedMe} alt="Ahhh!"/>
      </HeadSpace>
      </>
    )
}

export default Header;