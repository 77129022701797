import styled from 'styled-components';

const BlogWrapper = styled.div`

`;

const Blog = (props) => {
    return (
        <BlogWrapper>
            
        </BlogWrapper>
    )
}

export default Blog;